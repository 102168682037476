import React, { ReactElement, useState, useEffect } from 'react';
import './Main.scss';

import Legends_EE from '../../assets/images/legends_ee.png';
import Legends_LT from '../../assets/images/legends_lt.png';
import Legends_LV from '../../assets/images/legends_lv.png';

import Register from '../../components/register/Register';
import Header from '../../components/header/Header';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  type LegendsType = typeof Legends_EE | typeof Legends_LT | typeof Legends_LV;

  const [LegendsImg, setLegendsImg] = useState<LegendsType>(Legends_EE);

  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'et':
          setLegendsImg(Legends_EE);
          break;
        case 'lv':
          setLegendsImg(Legends_LV);

          break;
        case 'lt':
          setLegendsImg(Legends_LT);

          break;
        default:
          setLegendsImg(Legends_EE);

          break;
      }
    }, 100);
  }, []);

  return (
    <div className="Main">
      <section className="header">
        <Header />
      </section>
      {/* <section className="prizes container">
        <img src={Prizes} alt="" />
      </section> */}
      <section className="register-form container">
        <div className="columns">
          <div className="column">
            <Register />
          </div>
        </div>
      </section>
      <section className="footer">
        <img src={LegendsImg} alt="" />
      </section>
    </div>
  );
};

export default Main;
