import React, { ReactElement } from 'react';
import './Rules.scss';

const Lt: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Nėra „LAY‘S“ – nėra žaidimo</h2>

      <ol>
        <li>
          Vartotojų kampanija „Nėra „LAY‘S“ – nėra žaidimo“ yra Lietuvoje
          vykdoma kampanija, kurią organizuoja „Lay's“ traškučių platintojas
          Lietuvoje – UAB „Lithuanian Snacks“ (toliau – „kampanijos
          organizatorius“), įmonės kodas 111562753, Žalgirio g. 94, LT-09300,
          Vilnius. Loteriją organizuoja UAB „Sorbum LT“, įmonės kodas 125767647,
          J. Jasinskio g. 16G, LT-03163 Vilnius.
        </li>
        <li>
          Kampanijos teritorija – visos Lietuvos Respublikos teritorijoje
          esančios parduotuvės, kuriose yra parduodami kampanijos produktai.
        </li>
        <li>
          Kampanijoje gali dalyvauti įvairaus amžiaus Lietuvos gyventojai.
        </li>
        <li>
          Kampanija vykdoma nuo 2024 m. gegužės 13 d. iki liepos 7 d. (imtinai).
        </li>
        <li>Kampanijoje dalyvauja visi „Lay's“ produktai.</li>
        <li>
          Mažmeninėje prekyboje parduodamų ir kampanijoje dalyvaujančių produktų
          kiekis nėra ribotas. Atsižvelgiama į pagrįstą paklausą. Prieš arba po
          šiose sąlygose 4 punkte nurodytos datos parduotuvėse įsigyti „Lay's“
          produktai kampanijoje nedalyvauja.
        </li>
        <li>
          <span className="bold">Kampanijos principas </span>
          <span className="bold block">
            Vartotojas, norėdamas dalyvauti kampanijoje, turi įsigyti bent vieną
            „Lay's“ produktą ir užregistruoti pirkimo čekį interneto svetainėje{' '}
            <a href="http://www.lays.lt">www.lays.lt</a>. Bus lošiami šie
            prizai: 8 (aštuoni) 55 colių „Sony“ 4K „Google“ televizoriai ir 40
            (keturiasdešimt) „Apple iPad“ 10,9 colio planšetinių kompiuterių.
          </span>
          <ol>
            <li>
              Registruojant pirkimo čekį interneto svetainėje{' '}
              <a href="http://www.lays.lt">www.lays.lt</a>, reikia nurodyti
              kampanijoje dalyvaujančio asmens vardą, pavardę, pirkimo čekio
              numerį, telefono numerį ir el. paštą. Registracija svetainėje{' '}
              <a href="http://www.lays.lt">www.lays.lt</a> yra nemokama. Jeigu
              dalyviui nepasisektų užregistruoti pirkimo čekio numerio,
              puslapyje pasirodys klaidos pranešimas.
            </li>
            <li>
              Nepilnametis dalyvis gali registruotis interneto svetainėje{' '}
              <a href="http://www.lays.lt">www.lays.lt</a> gavęs savo teisėto
              atstovo sutikimą, nurodydamas atstovo el. pašto adresą ir asmens
              duomenis.
            </li>
            <li>
              Registruodamas pirkimo čekyje esantį numerį pagal šių taisyklių
              7.2 punktą, kampanijos dalyvis prisiima atsakomybę už teisingų
              asmens duomenų pateikimą ir padarytas klaidas.
            </li>
            <li className="bold">
              Kampanijos dalyvis privalo registruotą pirkimo čekį saugoti iki
              2024-07-31, kad galėtų įrodyti kampanijoje dalyvaujančio produkto
              pirkimo faktą.
            </li>
            <li>
              Vieną pirkimo čekio numerį per visą kampanijos laiką galima
              registruoti vieną kartą.
            </li>
            <li>
              Vienas dalyvis gali registruotis keletą kartų, jeigu kelis kartus
              perka kampanijoje dalyvaujančius produktus ir kiekvieną kartą
              registruoja naują čekio numerį{' '}
              <ol>
                <li>Su vienu čekiu galima laimėti vieną kartą.</li>
                <li>
                  Čekis, laimėjęs tarpinį prizą, didžiojo prizo lošime antrą
                  kartą nedalyvaus. Vienas čekis gali laimėti vieną kartą.
                </li>
                <li>
                  Tas pats dalyvis gali laimėti keletą kartų, jei užregistruoja
                  kelis skirtingus čekius.
                </li>
              </ol>
            </li>
            <li>
              Užregistruotas pirkimo čekis dalyvauja artimiausiame lošime;
              lošimo dienos yra nurodytos punkte 8.2.
            </li>
          </ol>
        </li>
        <li>
          Prizinis fondas{' '}
          <ol>
            <li>Prizinio fondo vertė yra 35 956 eurai.</li>
            <li>
              Prizai yra aštuoni 55 colių „Sony“ 4K „Google“ televizoriai (vieno
              televizoriaus vertė – 799 EUR) ir keturiasdešimt „Apple iPad“ 10,9
              colio planšetinių kompiuterių (vieno planšetinio kompiuterio vertė
              819 EUR). Prizai bus lošiami kartą per savaitę šiomis dienomis:{' '}
              <ol>
                <li>
                  2024-05-20 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-05-13 00:00:00 val. iki 2024-05-19 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-05-27 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-05-20 00:00:00 val. iki 2024-05-26 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-06-03 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-05-27 00:00:00 val. iki 2024-06-02 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-06-10 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-06-03 00:00:00 val. iki 2024-06-09 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-06-17 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-06-10 00:00:00 val. iki 2024-06-16 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-06-25 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-06-17 00:00:00 val. iki 2024-06-23 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-07-01 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-06-24 00:00:00 val. iki 2024-06-30 23:59:59 val.
                  registruoti čekiai.
                </li>
                <li>
                  2024-07-08 12:00 val. bus išlošti vienas „Sony“ televizorius
                  ir penkios „iPad“ planšetės. Lošime dalyvauja visi nuo
                  2024-07-01 00:00:00 val. iki 2024-07-07 23:59:59 val.
                  registruoti čekiai.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Dalyvių registraciją kampanijoje ir prizų lošimą užtikrins ir
          garantuos kampanijos organizatoriaus įgaliotasis atstovas UAB „Sorbum
          LT“, kuris taip pat susisieks su nugalėtojais.
        </li>
        <li>
          Asmens duomenų tvarkymo tvarka{' '}
          <ol>
            <li>
              Asmens duomenys, kuriuos dalyvis teikia kampanijos metu, yra
              saugomi ir tvarkomi UAB „Sorbum LT“ kampanijos tikslais,
              vadovaujantis Lietuvos Respublikos įstatymais.
            </li>
            <li>
              Kampanijos dalyvis sutinka, kad UAB „Sorbum LT“ tvarkytų pateiktus
              duomenis šiais tikslais:{' '}
              <ol>
                <li>apibendrinti kampanijos rezultatus;</li>
                <li>perduoti informaciją UAB „Sorbum LT“ partneriams; </li>
                <li>išaiškinti loterijos laimėtojus ir juos informuoti;</li>
                <li>
                  skelbti laimėtojo vardą ir pavardę kampanijos interneto
                  svetainėje.
                </li>
              </ol>{' '}
            </li>
            <li>
              Kampanijos dalyviai turi teisę gauti papildomą informaciją apie
              UAB „Sorbum LT“ tvarkomus asmens duomenis. Norėdami gauti
              informacijos, rašykite adresu: infoLT@pepsico.com.
            </li>
            <li>
              Asmens duomenų valdytojas yra UAB „Lithuanian Snacks“, įmonės
              kodas 111562753, adresas: Žalgirio g. 94, LT-09300, Vilnius,
              infoLT@pepsico.com.
            </li>
            <li>
              Surinkti duomenys yra saugomi vieną (1) mėnesį nuo kampanijos
              pabaigos datos arba iki 2024-08-08.
            </li>
          </ol>
        </li>
        <li>
          Laimėtojai skelbiami interneto svetainėje{' '}
          <a href="http://www.lays.lt">www.lays.lt</a>, ne vėliau nei iki 8.2
          punkte nurodytų datų darbo dienos pabaigos.
        </li>
        <li>
          Laimėtojų vardai ir pavardės interneto svetainėje bus prieinami 2
          (dvi) savaites pasibaigus kampanijai, t. y. iki 2024-07-21.
        </li>
        <li>
          u laimėtojais susisieks UAB „Sorbum LT“ atstovas registracijos formoje
          nurodytu el. pašto adresu arba telefonu ir susitars dėl prizo
          atsiėmimo laiko.
        </li>
        <li>
          Laimėtą prizą galima atsiimti UAB „Sorbum LT“ biure (J. Jasinskio g.
          16G, LT-03163 Vilnius). Norėdamas atsiimti prizą, laimėtojas privalo
          pateikti savo asmens tapatybę patvirtinantį dokumentą (pasą, asmens
          tapatybės kortelę, vairuotojo pažymėjimą) ir užregistruotą pirkimo
          čekį.{' '}
          <ol>
            <li>
              Jeigu laimėtojas nepateikia asmens tapatybę patvirtinančio
              dokumento arba jeigu dokumentuose nurodyti duomenys skiriasi nuo
              registracijos formoje nurodytų, laimėtojas neturi teisės gauti
              prizo.
            </li>
            <li>
              Jei laimėtojas nėra pilnametis, jo prizą gali atsiimti jo atstovas
              pagal įstatymą, pateikdamas laimėtojo ir savo asmens tapatybę
              patvirtinantį dokumentą (pasą, asmens tapatybės kortelę,
              vairuotojo pažymėjimą) ir laimėjusį čekį.{' '}
            </li>
          </ol>
        </li>
        <li>
          Perduodant prizą, laimėtojas ir kampanijos organizatoriaus įgaliotasis
          atstovas UAB „Sorbum LT“ pasirašo prizo perdavimo-priėmimo aktą. Po
          akto pasirašymo laimėtojas neturi teisės kampanijos organizatoriams
          pareikšti reikalavimų arba skundų.
        </li>
        <li>
          Kampanijos organizatoriai neatsako už bet kokią žalą, atsiradusią
          prizo naudojimo metu.
        </li>
        <li>Prizai nekeičiami į kitus prizus ar grynuosius pinigus.</li>
        <li>
          Su prizo atsiėmimu susijusios papildomos išlaidos, kurioms netaikomos
          šios taisyklės (pavyzdžiui, transporto ar skambinimo išlaidos),
          laimėtojui nekompensuojamos.
        </li>
        <li className="bold">
          Neatiduotas laimėtojui arba neatsiimtas laimėtojo prizas lošiamas iš
          naujo. Jeigu su prizo laimėtoju nepasiseka susisiekti per 5 darbo
          dienas, renkamas naujas laimėtojas.{' '}
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyviai
          nesilaiko šių taisyklių.
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyvis
          pašalinamas iš kampanijos ir prizas neperduodamas dėl kampanijos
          taisyklių nesilaikymo, jeigu kampanijos dalyvio pateikti duomenys yra
          klaidingi arba netikslūs, arba jeigu su dalyviu nepavyksta susisiekti
          dėl nuo organizatorių nepriklausančių priežasčių.
        </li>
        <li>
          Pasireiškus nenugalimos jėgos aplinkybei, kampanijos organizatorius
          turi teisę kampaniją nutraukti vienašališkai. Tokiu atveju kampanijos
          organizatorius privalo informuoti Lietuvos vartotojų teisių apsaugos
          tarnybą ir žiniasklaidoje paskelbti informaciją apie kampanijos
          sustabdymą.
        </li>
        <li>
          Visos su kampanijos organizavimu ir (arba) vykdymu susijusios
          pretenzijos ir skundai turi būti teikiami raštu iki 2024 m. liepos 14
          d. organizatoriui UAB „Sorbum LT“, adresu J. Jasinskio g. 16G,
          LT-03163 Vilnius arba el. paštu{' '}
          <a href="mailto:win@sorbum.eu">win@sorbum.eu</a>, su raktažodžiu „Nėra
          „LAY‘S“ – nėra žaidimo“ , pridedant pagrįstą skundą ir skundo
          dokumentų priedus arba jų kopijas{' '}
          <ol>
            <li>Vėliau nei 2024-07-14 pateikti skundai nebus nagrinėjami.</li>
            <li>
              Kampanijos organizatorius į pateiktą skundą atsako raštu per
              penkiolika dienų nuo jo gavimo. Jeigu kampanijos organizatorius
              mano, kad skundas yra pagrįstas, jis skundo pareiškėją informuoja
              apie skundo patenkinimo eigą ir datą. Jei skundo pateikėjas yra
              nepatenkintas gautu atsakymu, jis turi teisę įstatymo nustatyta
              tvarka ginti savo teises ir interesus.
            </li>
            <li>Skundo pateikimo data yra laikoma jo išsiuntimo data.</li>
            <li>Skunde būtina nurodyti lošimo dalyvio vardą ir pavardę.</li>
          </ol>
        </li>
        <li>
          Šiose taisyklėse yra nurodytos kampanijos organizatoriaus, jo atstovų
          ir dalyvių teisės bei pareigos.
        </li>
        <li>
          Kampanijos organizatorius įspėja, kad reklaminėje medžiagoje pateikta
          informacija yra tik informacinio pobūdžio.
        </li>
        <li>
          Kampanijoje negali dalyvauti UAB „Lithuanian Snacks“ ir UAB „Sorbum
          LT“ darbuotojai bei jų šeimos nariai.
        </li>
        <li>
          Kampanijos organizatorius tvarko duomenis pagal Europos asmens duomenų
          apsaugos reglamento nuostatas. Daugiau informacijos:{' '}
          <a href="https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt">
            https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Lt;
