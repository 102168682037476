import React, { FormEvent, ReactElement, useState } from 'react';
import './Register.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import axios from 'axios';
import _ from 'lodash';
import Loading from '../loading/Loading';
import { useNavigate } from 'react-router-dom';

const Register: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  type ValidationErrors = {
    First_Name: { state: boolean; message: string };
    Last_Name: { state: boolean; message: string };
    Email: { state: boolean; message: string };
    Phone: { state: boolean; message: string };
    Code: { state: boolean; message: string };
    Privacy: { state: boolean; message: string };
  };

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [errors, setValidationErrors] = useState<ValidationErrors>({
    First_Name: { state: false, message: '' },
    Last_Name: { state: false, message: '' },
    Email: { state: false, message: '' },
    Phone: { state: false, message: '' },
    Code: { state: false, message: '' },
    Privacy: { state: false, message: '' },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    // console.log('campaign is over');
    setLoading(true);
    const myForm = document.getElementById('register-form') as HTMLFormElement;
    const formData = new FormData(myForm);
    // formData.forEach(entries => console.log(entries));
    formData.append('lang', getLanguage());
    // console.log(typeof formData);

    axios
      .post('https://lays.ee.messenger.ee/api/submit/new', formData, {
        // Live push
        // .post('/api/submit/new', formData, {  // test
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        if (res.data.IsValid) {
          // console.log('valid');
          navigate('/thanks');
        } else {
          // console.log(res.data.Errors);
          setValidationErrors({
            First_Name: { state: false, message: '' },
            Last_Name: { state: false, message: '' },
            Email: { state: false, message: '' },
            Phone: { state: false, message: '' },
            Code: { state: false, message: '' },
            Privacy: { state: false, message: '' },
          });

          if (!_.isEmpty(res.data.Errors)) {
            const err = res.data.Errors;
            console.log(err.code);
            setValidationErrors({
              Code: _.isEmpty(err.code)
                ? { state: false, message: '' }
                : { state: true, message: err.code[0].message },
              Email: _.isEmpty(err.email)
                ? { state: false, message: '' }
                : { state: true, message: err.email[0].message },
              Phone: _.isEmpty(err.phone)
                ? { state: false, message: '' }
                : { state: true, message: err.phone[0].message },

              First_Name: _.isEmpty(err.first_name)
                ? { state: false, message: '' }
                : { state: true, message: err.first_name[0].message },
              Last_Name: _.isEmpty(err.last_name)
                ? { state: false, message: '' }
                : { state: true, message: err.last_name[0].message },
              Privacy: _.isEmpty(err.privacy)
                ? { state: false, message: '' }
                : { state: true, message: err.privacy[0].message },
            });
            // console.log(response.data.errors);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error);
      });
  };

  let CampaignOver = true;

  return (
    <section id="register" className="Register">
      <div className="container">
        <Loading loading={loading} />
        {/* <div className="register-header">
        <h2>{t('enter your information')}</h2>
      </div> */}
        <h2>{t('register header')}</h2>
        <form onSubmit={submitForm} id="register-form">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="first_name"
                placeholder={t('first name').toUpperCase()}
              />
            </div>
            {errors.First_Name.state && (
              <p className="help is-danger">
                {t('validation.' + errors.First_Name.message)}
              </p>
            )}
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="last_name"
                placeholder={t('last name').toUpperCase()}
              />
            </div>
            {errors.Last_Name.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Last_Name.message)}
              </p>
            )}
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="email"
                placeholder={t('email').toUpperCase()}
              />
            </div>
            {errors.Email.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Email.message)}
              </p>
            )}
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="phone"
                placeholder={t('phone').toUpperCase()}
              />
            </div>
            {errors.Phone.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Phone.message)}
              </p>
            )}
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="code"
                placeholder={t('campaign packaging unique code').toUpperCase()}
              />
            </div>
            {errors.Code.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Code.message)}
              </p>
            )}
          </div>

          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input name="privacy" type="checkbox" id="rules-check" />
                {t('I agree to the transfer of data')}
              </label>
            </div>

            {errors.Privacy.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Privacy.message)}
              </p>
            )}
          </div>

          {/* SUBMIT BUTTON */}
          {CampaignOver ? (
            <div className="campaign-over-wrapper">
              <span className="campaign-over">{t('campaign over')}</span>
              <span className="campaign-over">{t('congratulations')}</span>
            </div>
          ) : (
            <div className="submit-btn-wrapper">
              <button type="submit" className="button">
                {t('participate').toUpperCase()}
              </button>
              <div className="register-notice">
                {/* <p>{t('keep your receipt')}</p> */}
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default Register;
