import React, { ReactElement, useState, useEffect } from 'react';
import './Header.scss';
import Banner_EE from '../../assets/images/banner_ee.png';
import Banner_LT from '../../assets/images/banner_lt.png';
import Banner_LV from '../../assets/images/banner_lv.png';

import Banner_EE_Mobile from '../../assets/images/banner_ee_mobile.png';
import Banner_LT_Mobile from '../../assets/images/banner_lt_mobile.png';
import Banner_LV_Mobile from '../../assets/images/banner_lv_mobile.png';

import Prizes from '../../assets/images/prizes.png';

import Winners from '../winners/Winners';
import Rules from '../Rules/Rules';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Header: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [winnersActive, setWinnersActive] = useState<boolean>(false);

  const [rulesActive, setRulesActive] = useState<boolean>(false);

  type BannerType =
    | typeof Banner_EE
    | typeof Banner_LT
    | typeof Banner_LV
    | typeof Banner_LT_Mobile
    | typeof Banner_LV_Mobile
    | typeof Banner_EE_Mobile;

  const [BannerImg, setBannerImg] = useState<BannerType>(Banner_EE);

  const [BannerImgMobile, setBannerImgMobile] =
    useState<BannerType>(Banner_EE_Mobile);

  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'et':
          setBannerImg(Banner_EE);
          setBannerImgMobile(Banner_EE_Mobile);
          break;
        case 'lv':
          setBannerImg(Banner_LV);
          setBannerImgMobile(Banner_LV_Mobile);
          break;
        case 'lt':
          setBannerImg(Banner_LT);
          setBannerImgMobile(Banner_LT_Mobile);
          break;
        default:
          setBannerImg(Banner_EE);
          setBannerImgMobile(Banner_EE_Mobile);
          break;
      }
    }, 100);
  }, []);

  return (
    <div className="Header">
      <Winners
        winnersModalActive={winnersActive}
        setWinnersModalActive={setWinnersActive}
      />

      <Rules
        rulesModalActive={rulesActive}
        setRulesModalActive={setRulesActive}
      />
      <div className="banner-container">
        <img className="banner-image hidden-mobile" src={BannerImg} alt="" />
        <img
          className="banner-image hidden-desktop"
          src={BannerImgMobile}
          alt=""
        />
        <img className="prizes hidden-desktop" src={Prizes} alt="" />
      </div>
      <div className="description-container container is-max-desktop hidden-desktop">
        <div className="campaign-description">{t('campaign description')}</div>
        <div className="campaign-period">
          <span>{t('campaign period')}</span>
          <span className="campaign-code">{t('campaign code')}</span>
          {/* <span>{t('prizes')}</span> */}
        </div>
      </div>
      <div className="nav-container container">
        <HashLink to="#register" className="button">
          {t('register').toUpperCase()}
        </HashLink>
        <button
          className="button"
          onClick={() => setWinnersActive(!winnersActive)}
        >
          {t('winners').toUpperCase()}
        </button>
        <button className="button" onClick={() => setRulesActive(!rulesActive)}>
          {t('rules').toUpperCase()}
        </button>
      </div>
      <div className="description-container container is-max-desktop hidden-mobile">
        <div className="campaign-description">{t('campaign description')}</div>
        <div className="campaign-period">
          <span>{t('campaign period')}</span>
          <span className="campaign-code">{t('campaign code')}</span>
          {/* <span>{t('prizes')}</span> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
