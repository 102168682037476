import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>„Pole Lay’si, pole mängu“ kampaania 2024</h2>

      <ol>
        <li>
          Tarbijakampaania „Pole Lay’si, pole mängu“ on Eestis toimuv kampaania,
          mida korraldab Lay’si krõpsude edasimüüja Eestis – Pepsico Eesti AS
          (edaspidi kampaania korraldaja), registrinumber 10438708, Tuglase 14,
          51006 Tartu. Loosimist korraldab Direct Messenger OÜ, registrinumber
          11981389, Mustamäe tee 44/1, 10621 Tallinn.
        </li>
        <li>
          Kampaania territoorium – kõik Eesti Vabariigi territooriumil asuvad
          kauplused, kus kampaanias osalevaid tooteid müüakse.
        </li>
        <li>Kampaanias võivad osaleda igas vanuses Eesti elanikud.</li>
        <li>Kampaania leiab aset 13. maist 7. juulini 2024 (k.a).</li>
        <li>Kampaania hõlmab kõiki Lay’si tooteid.</li>
        <li>
          Jaemüügis saadaolevate kampaaniatoodete arv ei ole piiratud.
          Arvestatakse mõistliku nõudlusega. Lay’si tooted, mis on ostetud enne
          või pärast käesolevate reeglite punktis 4 nimetatud ajavahemikku, ei
          anna õigust kampaanias osaleda.
        </li>
        <li>
          <span className="block bold">Kampaania põhimõtted</span>
          <span className="block bold">
            Kampaanias osalemiseks peab tarbija ostma kauplusest vähemalt ühe
            Lay’si toote ja registreerima ostutšeki veebilehel{' '}
            <a href="http://www.lays.ee">www.lays.ee</a>. Osalejate vahel
            loositakse välja 8 (kaheksa) 55” Sony 4K Google’i telerit ja 40
            (nelikümmend) Apple iPadi 10,9" tahvelarvutit.
          </span>

          <ol>
            <li>
              Registreerides ostutšeki veebilehel{' '}
              <a href="http://www.lays.ee">www.lays.ee</a>, tuleb ära märkida
              loosis osaleja eesnimi, perekonnanimi, ostutšeki number,
              telefoninumber ja e-posti aadress. Registreerimine veebilehel{' '}
              <a href="http://www.lays.ee">www.lays.ee</a> on tasuta. Kui
              osalejal ei õnnestu korrektselt registreeringut teha, ilmub lehele
              veateade.
            </li>
            <li>
              Alaealine võib veebilehel <a href="">www.lays.ee </a>ostutšeki
              registreerida tema seadusliku esindaja nõusolekul esindaja e-posti
              aadressi ja isikuandmetega.
            </li>
            <li>
              Registreerides ostutšeki vastavalt käesolevate reeglite
              punktile 7.2, võtab kampaanias osaleja vastutuse korrektsete
              isikuandmete esitamise ja nende esitamisel tehtud vigade eest.
            </li>
            <li className="bold">
              Kampaanias osaleja hoiab registreeritud ostutšeki alles kuni
              31.07.2024, et tõendada kampaanias osaleva toote ostmist.
            </li>
            <li>
              Ühte ostutšekki saab kogu kampaania toimumise ajal registreerida
              ühe korra.
            </li>
            <li>
              Üks ja sama osaleja võib teha mitu registreerimist, kui ostab
              kampaanias osalevaid tooteid mitu korda ja registreerib iga kord
              uue ostutšeki.
              <ol>
                <li>
                  Juhul, kui üks ja sama isik üritab sama ostutšeki numbrit
                  sisestada mitu korda, osaleb loosimises ainult esimene
                  korrektselt tehtud registreering, kõik korduvad tühistatakse.
                </li>
                <li>Ühe ostutšekiga saab võita ühe korra.</li>
                <li>
                  Sama osaleja võib võita mitu korda, kui on registreeritud mitu
                  ostutšekki.
                </li>
              </ol>
            </li>
            <li>
              Pärast ostutšeki registreerimist osaleb see auhindade loosimises.
              Loosimiste kuupäevad on välja toodud punktis 8.2.
            </li>
          </ol>
        </li>
        <li>
          <span className="bold">Auhinnafond</span>
          <ol>
            <li>Auhinnafondi koguväärtus on 35 956 €.</li>
            <li>
              Auhindadeks on{' '}
              <span className="bold">kaheksa 55” Sony 4K telerit</span> (ühe
              teleri väärtus 799 €) ning nelikümmend Apple iPadi 10,9“
              tahvelarvutit (ühe tahvelarvuti väärtus 819 €). Auhindade
              loosimised toimuvad kord nädalas järgnevatel kuupäevadel:
              <ol>
                <li>
                  20.05.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 13.05.2024 kell 00:00:00 kuni
                  19.05.2024 kell 23:59:59.{' '}
                </li>
                <li>
                  27.05.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 20.05.2024 kell 00:00:00 kuni
                  26.05.2024 kell 23:59:59.
                </li>
                <li>
                  03.06.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 27.05.2024 kell 00:00:00 kuni
                  02.06.2024 kell 23:59:59.
                </li>
                <li>
                  10.06.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 03.06.2024 kell 00:00:00 kuni
                  09.06.2024 kell 23:59:59.
                </li>
                <li>
                  17.06.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 10.06.2024 kell 00:00:00 kuni
                  16.06.2024 kell 23:59:59.
                </li>
                <li>
                  25.06.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 17.06.2024 kell 00:00:00 kuni
                  23.06.2024 kell 23:59:59.{' '}
                </li>
                <li>
                  01.07.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 24.06.2024 kell 00:00:00 kuni
                  30.06.2024 kell 23:59:59.
                </li>
                <li>
                  08.07.2024 kell 12:00 loositakse välja üks Sony teler ja viis
                  iPadi tahvelarvutit. Loosis osalevad kõik tšekid, mis on
                  registreeritud ajavahemikus 01.07.2024 kell 00:00:00 kuni
                  07.07.2024 kell 23:59:59.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Osalejate registreerimise kampaanias ja auhindade väljaloosimise tagab
          kampaania korraldaja volitatud esindaja Direct Messenger OÜ, kes võtab
          ka võitjatega ühendust.
        </li>
        <li>
          <span className="bold">Isikuandmete töötlemise kord</span>
          <ol>
            <li>
              Isikuandmed, mille osaleja kampaania käigus esitab, on kaitstud ja
              PepsiCo Eesti AS töötleb neid kooskõlas Eesti Vabariigis kehtivate
              seadustega kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab PepsiCo Eesti AS-ile nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:{' '}
              <ol>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks; </li>
                <li>
                  PepsiCo Eesti AS-i poolt kampaaniaga seotud
                  koostööpartneritele info edastamiseks;
                </li>
                <li>võitjate loosimiseks ja nende teavitamiseks;</li>
                <li>võitjate nimede avaldamiseks kampaania veebilehel.</li>
              </ol>
            </li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot selle kohta, kuidas
              PepsiCo Eesti AS isikuandmeid töötleb. Selleks tuleb saata e-kiri
              aadressile{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
              .
            </li>
            <li>
              Isikuandmete vastutav töötleja on PepsiCo Eesti AS, registrikood
              10438708, Tuglase 14, 51006 Tartu, Eesti, tel 738 6600,
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
              .
            </li>
            <li>
              Kogutud andmeid säilitatakse 1 (üks) kuu alates kampaania
              lõpukuupäevast ehk kuni 08.08.2024.
            </li>
          </ol>
        </li>
        <li>
          Võitjate nimed avalikustatakse kolme tööpäeva jooksul alates punktis
          8.2 mainitud kuupäevadest veebilehel www.lays.ee.
        </li>
        <li>
          Võitjate nimed on veebilehel üleval 2 (kaks) nädalat pärast kampaania
          lõppu ehk kuni 21.07.2024.
        </li>
        <li>
          Võitjatega võtab ühendust Direct Messenger OÜ esindaja
          registreerimisvormis märgitud e-posti või telefoni teel, et leppida
          kokku auhinna üleandmise aeg.
        </li>
        <li>
          Võidetud auhinna saab kätte Direct Messenger OÜ kontorist (aadressil
          Mustamäe tee 44/1, Tallinn). Auhinna kättesaamiseks peab võitja
          esitama oma isikut tõendava dokumendi (pass, ID-kaart, juhiluba) ning
          registreeritud ostutšeki.{' '}
          <ol>
            <li>
              Kui auhinna võitja ei esita isikut tõendavat dokumenti ja/või
              ostutšekki või kui dokumentidel toodud andmed erinevad
              registreerimisvormil toodust, ei ole võitjal õigust auhinda saada.
            </li>
            <li>
              Kui auhinna võitja on alaealine, võib tema auhinna vastu võtta
              tema seaduslik esindaja, esitades võitja ja tema enda isikut
              tõendava dokumendi (pass, ID-kaart, juhiluba) ning registreeritud
              ostutšeki.
            </li>
          </ol>
        </li>
        <li>
          Auhinna üleandmisel allkirjastavad võitja ja kampaania korraldaja
          volitatud Direct Messenger OÜ esindaja auhinna üleandmise-vastuvõtmise
          akti. Pärast akti allkirjastamist ei ole võitjal õigust kampaania
          korraldajale auhinna suhtes nõudeid ega kaebusi esitada.
        </li>
        <li>
          Kampaania korraldaja ei vastuta kahju eest, mis on auhinna võitjale
          tekkinud auhinna kasutamise käigus.
        </li>
        <li>Auhinda ei saa vahetada teise auhinna ega raha vastu.</li>
        <li>
          Auhinna kättesaamisega seotud lisakulusid, mida ei ole käesolevates
          reeglites reguleeritud (nt transpordi- või helistamiskulud), võitjale
          ei kompenseerita.
        </li>
        <li className="bold">
          Auhind, mida võitjale välja ei anta või mille võitja jätab välja
          võtmata, loositakse uuesti välja. Kui auhinna võitjaga ei saada viie
          tööpäeva jooksul ühendust, loositakse uus võitja.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osalejad käesolevaid reegleid ei järgi.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osaleja jääb loosimisest välja, kui auhinda reeglite mittejärgimise
          tõttu välja ei anta, kui kampaanias osaleja esitatud andmed on väärad
          või ebatäpsed või kui võitjatega ei ole võimalik ühendust saada
          kampaania korraldajast sõltumatutel põhjustel.
        </li>
        <li>
          Vääramatu jõu asjaolude esinemisel on kampaania korraldajal õigus
          kampaania ühepoolselt katkestada. Sellisel juhul on kampaania
          korraldajal kohustus teavitada Tarbijakaitse- ja Tehnilise Järelevalve
          Ametit ning edastada teade kampaania peatamise kohta ka massimeedia
          kaudu.
        </li>
        <li>
          Kõik nõuded ja kaebused seoses kampaania korralduse ja/või
          läbiviimisega tuleb esitada kirjalikult kampaania korraldajale Pepsico
          Eesti AS-ile 14. juuliks 2024 aadressil Tuglase 14, 51006 Tartu, või
          e-kirja teel aadressile{' '}
          <a href="mailto:pepsico.eesti@pepsico.com">
            pepsico.eesti@pepsico.com
          </a>
          , märkides märksõnaks „Lay’s Movies“, esitades oma põhjendatud kaebuse
          ning lisades kaebuses viidatud dokumendid või nende koopiad.{' '}
          <ol>
            <li>
              Kaebusi, mis esitatakse pärast 14. juulit 2024, ei võeta
              menetlusse.
            </li>
            <li>
              Kampaania korraldaja vastab esitatud kaebusele kirjalikult
              viieteistkümne päeva jooksul pärast selle kättesaamist. Kui
              kampaania korraldaja peab kaebust õigustatuks, teavitab ta kaebuse
              esitajat kaebuse rahuldamise protsessist ja kuupäevast. Kui
              kaebuse esitaja ei ole saadud vastusega rahul, on tal õigus
              seadusega ettenähtud korras oma õigusi ja huve kaitsta.
            </li>
            <li>
              Kaebuse esitamise kuupäevaks loetakse selle postitamise kuupäeva.
            </li>
            <li>
              Kaebusele tuleb alati lisada loosis osaleja eesnimi ja
              perekonnanimi.
            </li>
          </ol>
        </li>
        <li>
          Käesolevates reeglites on sätestatud nii kampaania korraldaja, tema
          esindajate kui ka osalejate õigused ja kohustused.
        </li>
        <li>
          Kampaania korraldaja hoiatab, et reklaammaterjalides esitatud teabel
          on ainult teavitav eesmärk.
        </li>
        <li>
          Kampaanias ei tohi osaleda PepsiCo Eesti AS-i, Direct Messenger OÜ
          ning Zavod Advertising OÜ töötajad ja perekonnaliikmed.
        </li>
        <li>
          Kampaania korraldaja töötleb andmeid vastavalt Euroopa Liidu
          isikuandmete kaitse üldmäärusele. Lähemalt:
          <a href="https://pepsicoprivacypolicy.com/et?domain=pepsico.ee">
            https://pepsicoprivacypolicy.com/et?domain=pepsico.ee
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Et;
